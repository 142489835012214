import React, { Component } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import Loading from './components/utils/Loading';
import MainApp from './components/main_screens/main_app';
import Login from './components/main_screens/login';
import AgeVerification from './components/main_screens/AgeVerification';
import {base} from './base';
import ReactGA from 'react-ga';

if(process.env.NODE_ENV === "production"){
  ReactGA.initialize(process.env.REACT_APP_GA_KEY);
}

function logPageView() {
  if(process.env.NODE_ENV === "production"){
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname)
  }
}

class App extends Component {
  constructor(props) {
        super(props)
        this.state = {
          authenticated: false,
          variables: {},
          loading: true
        }
    }

  componentDidMount() {
      let langague = "english"
      let splitWindowLocation = window.location.pathname.split('/') || ""
      if(window.location.pathname.split('/').length > 2){
          langague = splitWindowLocation[2]
      } else if(window.location.pathname.split('/').length > 1){
          langague = splitWindowLocation[1]
      }
      this.loadLanguage(langague)
    this.tenantVariablesRef = base.listenTo(`tenantVariables`, {
      context: this,
      state: 'tenantVariables',
      then(variables){
          document.title = variables.pageTitle || "Photo Scramble";
        this.setState({
            authenticated: false,
            variables: variables,
            loading: false,
        })
      }
    });
  }

  setPassedEmail(isSet=true){
        this.setState({
            passedEmail: isSet
        })
    }

  checkForAgeGate(tenantVariables){
    const variable_age_boolean = tenantVariables.collectBirthday || false;
    if(!variable_age_boolean){
        return true
    }
    const formBirthday = tenantVariables.formBirthday || false;
    let variable_age = tenantVariables.allowedAge || 21;
    variable_age = parseInt(variable_age, 10);
    let user_age = sessionStorage.getItem('verifiedAge') || false;
    if(isNaN(parseInt(user_age))){
        user_age = false
    } else {
        user_age = parseInt(user_age, 10)
    }
    return (user_age && user_age >= variable_age) || formBirthday;
    }

  componentWillUnmount() {
      base.removeBinding(this.tenantVariablesRef);
    }

  async loadLanguage(langauge){
    let languagesConstants = null
    if(langauge){
        try {
            languagesConstants = await import("./constants/languages/"+langauge+".js");
        } catch(e) {
            langauge = process.env.REACT_APP_DEFAULT_LANGUAGE;
            languagesConstants = await import("./constants/languages/"+langauge+".js");
        }
    } else {
        langauge = process.env.REACT_APP_DEFAULT_LANGUAGE;
        languagesConstants = await import("./constants/languages/"+langauge+".js");
    }
    this.setState({
        lanaguageConstants: languagesConstants
    })
  }

  render() {
    if (this.state.loading === true) {
        return (
            <Loading loading={this.state.loading}/>
        )
    }
    let redirectUrl = `/login`;
    if(this.state.langague){
        redirectUrl += ("/" + this.state.langague);
    }
    return (
      <div style={{ margin: "0 auto"}}>
        <BrowserRouter onUpdate={logPageView()}>
            <div>
                <div className="main-content">
                    <div className="workspace">
                      <Switch>
                        <Route
                            strict
                          path="/login"
                          render={(props) => {
                            return <Login variables={this.state.variables} setPassed={(setPassed)=>this.setPassedEmail(setPassed)} stringConstants={this.state.lanaguageConstants} checkForAgeGate={this.checkForAgeGate} {...props} />
                          }}
                        />
                        <Route
                          path="/"
                          render={(props) => {
                            return <MainApp variables={this.state.variables} passedEmail={this.state.passedEmail} stringConstants={this.state.lanaguageConstants} checkForAgeGate={this.checkForAgeGate} {...props} />
                          }}
                        />

                          <Route
                              strict
                              path="/age_gate"
                              render={(props) => {
                                  return <AgeVerification variables={this.state.variables} passedEmail={this.state.passedEmail} stringConstants={this.state.lanaguageConstants} checkForAgeGate={this.checkForAgeGate} {...props} />
                              }} />

                          <Redirect to={redirectUrl}/>
                      </Switch>
                    </div>
                </div>
            </div>
        </BrowserRouter>
      </div>
    )
  }
}

export default App;
